
import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import moment from "moment";
import numeral from "numeral";
import * as salesActions from "../../store/actions/sales"
import * as accountActions from "../../store/actions/account"

import { Col, Row } from "react-bootstrap";
import { InputLabel, FormControl, Select, TextField, Button, Stack, MenuItem, Typography, Divider, Box, Autocomplete } from '@mui/material';
import { DataGrid, GridFooter, GridFooterContainer } from "@mui/x-data-grid";
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import NoRows from "../../reUsable/NoRows"
import Skeleton from '../../reUsable/skeleton';
import { withdrawalCol } from "../sales/cols";
import "../accountMain/components/sale.css"
import *  as payoutAction from 'store/actions/payout'
import PayoutModal from "reUsable/payoutModal";
import UserModal from "../../reUsable/UserModal";
import DateBtn from "reUsable/DateBtn";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { parseISO } from "date-fns";



function Payout({ getWithdrawals, withdrawal, accountList, getLegProfile, getALLWithdrawRequest, payout, handleWithdrawRequest, changePayout, from }) {
    const [value, setValue] = useState([moment().startOf('year').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')])
    const [loading, setLoading] = useState(true)
    const [search, setSearch] = useState(false)
    const [page, setPage] = useState(1);
    const [date, setDate] = useState(false)
    const [fields, setFields] = useState({ orderBy: -1, sort: "created_date", account_type: "all", id: '', status: 'Pending' })
    const [data, setData] = useState([])
    const [load, setLoad] = useState(true)
    const [pageLoad, setPageLoad] = useState(false)
    const [name, setName] = useState('')
    const [nameLoad, setNameLoad] = useState(false)
    const [decision, setDecision] = useState("")
    const [show, setShow] = useState(false)
    const [datatoSend, setDatatoSend] = useState({ _id: "", status: "" })
    const [message, setMessage] = useState("")
    const [modalLoading, setMoalLoading] = useState(false)

    const [user, setUser] = useState(null)
    const [userModal, setUserModal] = useState(false)

    const fileName = `withdrawal_${fields.account_type}`;
    useEffect(() => {
        getALLWithdrawRequest(fields, page, value).then((res) => {
            setLoading(false)
        })
    }, [])
    useEffect(() => {
        if (search || date) {
            setLoading(true)
            getALLWithdrawRequest(fields, 1, value).then((res) => {
                setLoading(false)
                setDate(false)
                setSearch(false)
            })
        }
    }, [search, date]);

    useEffect(() => {
        if (user) {
            setUserModal(true)
        }
    }, [user])

    const payCol = [
        {
            field: 'id', headerName: 'S.#', width: 60, sortable: false,
            renderCell: ({ value }) => <span className="d-flex justify-content-center w-100">{value}</span>, sortable: false,
        },
        {
            field: "created_date", headerName: "Date", width: 120, sortable: false, renderCell: ({ value }) => {
                return moment(value).format('MM-DD-YYYY')
            }
        },
        {
            field: "name", headerName: "Withdrawer Name", width: 200, sortable: false, renderCell: (params) => {
                return (
                    <span
                        className="account-name-hover-style"
                        onClick={() => {
                            if (from !== 'finance') {
                                setUser({
                                    id: params.row.user_id,
                                    name: params?.row?.name,
                                    image: params?.row?.userData?.[0]?.profile_image_url,
                                    customer_id: params?.row?.userData?.[0]?.customer_id,
                                    userType: 'influencer'
                                })
                            }

                        }}
                    >
                        {params.row.name ? params.row.name : <span style={{ color: "#bebebe" }}>Empty</span>}
                    </span>
                );
            },
        },
        { field: "email", headerName: "Withdrawer Email", width: 220, sortable: false },
        { field: "amount", headerName: "Withdraw Amount", width: 150, sortable: false, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
        { field: "status", headerName: "Status", width: 130, sortable: false, },
        {
            field: 'actions',
            headerClassName: 'super-app-theme--header',
            headerName: "Actions",
            flex: 1.5,
            filterable: false,
            minWidth: 250,
            textAlign: 'center',
            renderCell: (value) => {
                return (
                    <div style={{ marginLeft: 'auto', marginRight: 'auto', width: '100%' }}>
                        <>
                            <Button
                                disabled={from === "finance"}
                                onClick={() => {
                                    setDatatoSend({ ...datatoSend, status: "approve", _id: value.value })
                                    setDecision("Approve")
                                    setShow(true)
                                }}
                                className='btn-gen-margin '
                                variant="contained"
                                style={{ fontSize: "10px" }}
                                color="primary"
                                disableElevation
                            >
                                Approve
                            </Button>
                        </>

                        <Button
                            disabled={from === "finance"}
                            onClick={() => {
                                setDatatoSend({ ...datatoSend, status: "reject", _id: value.value })
                                setDecision("Reject")
                                setShow(true)
                            }}
                            className='btn-gen-margin'
                            variant="contained"
                            style={{ fontSize: "10px" }}
                            color="error"
                            disableElevation
                        >
                            Reject
                        </Button>
                    </div>
                )
            }
        },
    ]
    const restCol = [
        {
            field: 'id', headerName: 'S.#', width: 60, sortable: false,
            renderCell: ({ value }) => <span className="d-flex justify-content-center w-100">{value}</span>, sortable: false,
        },
        {
            field: "created_date", headerName: "Date", width: 120, sortable: false, renderCell: ({ value }) => {
                return moment(value).format('MM-DD-YYYY')
            }
        },
        {
            field: "name", headerName: "Withdrawer Name", width: 200, sortable: false, renderCell: (params) => {
                return (
                    <span
                        className="account-name-hover-style"
                        onClick={() => {
                            setUser({
                                id: params.row.user_id,
                                name: params?.row?.name,
                                image: params?.row?.userData?.[0]?.profile_image_url,
                                customer_id: params?.row?.userData?.[0]?.customer_id,
                                userType: 'influencer'
                            })

                        }}
                    >
                        {params.row.name ? params.row.name : <span style={{ color: "#bebebe" }}>Empty</span>}
                    </span>
                );
            },
        },
        { field: "email", headerName: "Withdrawer Email", width: 220, sortable: false },
        { field: "amount", headerName: "Withdraw Amount", width: 150, sortable: false, renderCell: ({ value }) => numeral(value).format("$0,0.00", Math.floor), sortable: false, },
        { field: "status", headerName: "Status", width: 130, sortable: false, },
        { field: "approver_email", headerName: "Approver Email", width: 220, sortable: false, },
        { field: "department", headerName: "Department", width: 120, sortable: false, },


    ]


    const handleSearch = (type) => {
        setPage(1)
        if (type === "refresh") {
            setValue([moment().startOf('year').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')])
            setFields({ orderBy: -1, sort: "created_date", account_type: "all", id: '', status: 'Pending' })
            setName('')
        }
        setSearch(true)
    }

    const submitFn = () => {
        setMoalLoading(true)
        handleWithdrawRequest(datatoSend).then((res) => {
            if (res?.success) {
                setMessage("Request has been updated.")
                setLoading(true)
                getALLWithdrawRequest(fields, page, value).then((res) => {
                    setLoading(false)
                })
            } else {
                setMessage("An error occured. Try again later.")
            }
            setMoalLoading(false)
            setDatatoSend({ _id: "", status: "" })
        })
    }
    function formatToTwoDecimalPlaces(value) {
        return Math.floor(value * 100) / 100;
    }
    return (
        <React.Fragment>
            <UserModal
                from={'withdraw'}
                visible={userModal}
                close={() => {
                    setUser(null)
                    setUserModal(false);
                }}
                userImage={user?.image}
                userName={user?.name}
                deleted={false}
                currentCustomerId={user?.customer_id}
                currentUser={user?.id}
                nowGet={() => { }}
                userType={'influencer'}
            />
            <div className='container-fluid mt-3 mb-3 pl-0'>
                <Row className='pl-15'>
                    <Col xs className="mb-3 d-flex align-items-center">
                        <Typography className='title-bar-hd-main' color="primary" variant="h5" gutterBottom component="div">
                            User Withdraw Requests
                        </Typography>
                    </Col>
                </Row>
                <DateBtn value={value} setValue={(data) => {
                    setValue(data)
                }} setDate={setDate} />
                <Row className="account-main-row pl-15">
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <div className="dateBox">
                                <DatePicker
                                    minDate={new Date(2023, 0, 1)}
                                    slotProps={{
                                        textField: {
                                            helperText: "YYYY / MM / DD",
                                        },
                                    }}
                                    allowSameDateSelection
                                    sx={{ color: "black" }}
                                    label="Start Date"
                                    value={parseISO(value[0])}
                                    onChange={(newValue) => {
                                        setValue([moment(newValue).format("YYYY-MM-DD"), value[1]]);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            className="field-style"
                                            size="small"
                                            style={{ backgroundColor: "white", width: "50%" }}
                                            {...params}
                                        />
                                    )}
                                />
                                <Box sx={{ mx: 2 }}> to </Box>
                                <DatePicker
                                    minDate={new Date(2023, 0, 1)}

                                    slotProps={{
                                        textField: {
                                            helperText: "YYYY / MM / DD",
                                        },
                                    }}
                                    allowSameDateSelection
                                    sx={{ color: "black" }}
                                    label="End Date"
                                    value={parseISO(value[1])}
                                    onChange={(newValue) => {
                                        setValue([value[0], moment(newValue).format("YYYY-MM-DD")]);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            className="field-style"
                                            size="small"
                                            style={{ backgroundColor: "white", width: "50%" }}
                                            {...params}
                                        />
                                    )}
                                />
                            </div>
                        </LocalizationProvider>
                    </Col>
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <FormControl className='field-style' size="small">
                            <InputLabel
                                id="demo-simple-select-label1"
                            >Status</InputLabel>
                            <Select
                                sx={{ color: "black" }}
                                labelId="Status"
                                id="Status"
                                value={fields.status}
                                label="Status"
                                onChange={(e) => setFields({ ...fields, status: e.target.value })}
                            >
                                <MenuItem key={"all"} value={"All"} >All</MenuItem>
                                <MenuItem key={"pending"} value={"Pending"} >Pending</MenuItem>
                                <MenuItem key={"processing"} value={"Processing"} >Processing</MenuItem>
                                <MenuItem key={"reject"} value={"Rejected"} >Rejected</MenuItem>
                                <MenuItem key={"approved"} value={"Approved"} >Approved</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <Button
                            variant="contained"
                            size="medium"
                            className="btn-gen-margin btn-width laptop"
                            color="primary"
                            startIcon={<SearchIcon />}
                            disableElevation
                            onClick={() => { handleSearch() }}
                        >
                            Search
                        </Button>
                        <Button
                            variant="contained"
                            size="medium"
                            startIcon={<RefreshIcon />}
                            className="btn-gen-margin btn-width laptop"
                            color="success"
                            disableElevation
                            onClick={() => { handleSearch('refresh') }}
                        >
                            Refresh
                        </Button>
                    </Col>

                </Row>
                <Divider className="separater-sale ml-15" />
                <div style={{ height: 460 }} className="pl-3">
                    {
                        loading ? <Skeleton /> :
                            <>
                                <DataGrid
                                    density="compact"
                                    loading={pageLoad}
                                    components={{ NoRowsOverlay: NoRows }}
                                    disableColumnSelector
                                    disableColumnMenu
                                    disableSelectionOnClick
                                    rows={
                                        payout?.status === "Pending" ?
                                            Object.keys(payout).length !== 0 ? payout?.data?.map((m, index) => ({
                                                ...m,
                                                id: index + (page == 1 ? 1 : (page * 10) - 9),
                                                created_date: m.created_date,
                                                amount: numeral(m.withdraw_amount).format("$0,0.00", Math.floor),
                                                status: m.status ? m.status : "Pending",
                                                name: m?.userData?.[0]?.name,
                                                email: m?.userData?.[0]?.email,
                                                actions: m?._id
                                            })) : [] : Object.keys(payout).length !== 0 ? payout?.data?.map((m, index) => ({
                                                ...m,
                                                id: index + (page == 1 ? 1 : (page * 10) - 9),
                                                created_date: m.created_date,
                                                amount: numeral(m.withdraw_amount).format("$0,0.00", Math.floor),
                                                status: m.status ? m.status : "Pending",
                                                name: m?.userData?.[0]?.name,
                                                email: m?.userData?.[0]?.email,
                                                department: m?.department,
                                                approver_email: m?.approver_email
                                            })) : []

                                    }
                                    columns={payout?.status === "Pending" ? payCol : restCol}
                                    rowCount={Object.keys(payout).length !== 0 ? payout?.totalCount : 0}
                                    pagination
                                    pageSize={10}
                                    rowsPerPageOptions={[10]}
                                    paginationMode="server"
                                    onPageChange={(newPage) => {
                                        setPage(newPage + 1);
                                        setPageLoad(true)
                                        getALLWithdrawRequest(fields, newPage + 1, value).then(() => {
                                            setPageLoad(false)
                                        })
                                    }}
                                />
                                <div className='sumContainer-height-235  pb-2' style={{ background: "#fff" }} >
                                    <div className='sumContainer'>
                                        <div className='sumTitle'>Summary</div>
                                        <div className='sumBody'>
                                            <div className='sumBodyRow'>
                                                <div className='sumBodyTitle'>Total Records</div>
                                                <div className='sumBodyvalue'>{payout?.summary?.records}</div>
                                            </div>
                                            <div className='sumBodyRow' style={{ borderBottom: "none" }}>
                                                <div className='sumBodyTitle'>Total Amount</div>
                                                <div className='sumBodyvalue'>{numeral(payout?.summary?.total_amount).format("$0,0.00", Math.floor)}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>

                    }
                </div>
            </div>
            <PayoutModal visible={show} setShow={setShow} approve={decision} submitFn={submitFn} setDatatoSend={setDatatoSend} setMessage={setMessage} message={message} loading={modalLoading} />
        </React.Fragment>
    )
}

function mapStateToProps({ accountList, withdrawal, payout, changePayout }) {
    return { accountList, withdrawal, payout }
}
export default connect(mapStateToProps, { ...salesActions, ...accountActions, ...payoutAction })(Payout)
