import React, { useState } from "react"
import { Row, Col, Container } from "react-bootstrap"
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { connect } from "react-redux";
import Modal from '@mui/material/Modal';
import CancelIcon from '@mui/icons-material/Cancel';
import Conditional from "../pages/accountMain/Conditional";
import * as minimizeActions from "../store/actions/minimiz"
import RemoveIcon from '@mui/icons-material/Remove';
import Slide from '@mui/material/Slide';
import { Avatar } from "@mui/material";
import defaultImg from "../assets/images/default.jpg";
import { useSelector } from "react-redux";

function UserModal({ close, visible, currentUser, addMinimize, userName, deleted, currentCustomerId, nowGet, userType, userImage, editUser, from }) {
    const [active, setActive] = useState(1)

    const accountDetail = useSelector((state) => state.accountDetail);

    // const color = accountDetail?.userDetails?.cover_color
    // const coverImage = accountDetail?.userDetails?.cover_image

    const [color, setColor] = useState('');
    const [coverImage, setCoverImage] = useState('');
    const [loading, setLoading] = useState(true);

    // useEffect(() => {
    //     // Simulate delay with a timer
    //     const timer = setTimeout(() => {
    //         setColor(accountDetail?.userDetails?.cover_color || '');
    //         setCoverImage(accountDetail?.userDetails?.cover_image || '');
    //         setLoading(false); // Stop loading after delay
    //     }, 1500); // 1.5 seconds delay

    //     return () => clearTimeout(timer); // Cleanup timer on unmount
    // }, [accountDetail]);

    return (

        <>


            < div >
                <Modal
                    className="right-content-modal-main"
                    open={visible}
                    onClose={() => {    
                        setActive(1)
                        close()
                    }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Slide direction="up" in={visible} mountOnEnter unmountOnExit appear={editUser ? false : true}>
                        <Box className="user-info-modal-main full-screen mobile-screen">
                            <Container className="users-main-container" style={{ borderRadius: 16 }}>
                                <Row className="users-main-row">
                                    <Col sm={4} xl={2} className="left-column">
                                        <div className='admin-profile-box userModal-profile'>
                                            <div className="pic-area">
                                                <img className='admin-profile-pic mb-0'
                                                    src={userImage ? userImage : defaultImg}
                                                />
                                                <h6 className="mb-0">{userName}</h6>
                                            </div>


                                            {/* <div className="bio-profile-bg">
                                            <img
                                                src="https://d1hq75pouwojqi.cloudfront.net/900000663/profile/14865fa3-494f-424b-be33-c84e6e21cb11.jpeg"
                                                alt="media_image"
                                                className="new-bio-bg"
                                            />
                                            <div
                                                className="profile-color"
                                                style={{
                                                    background: 'linear-gradient(to top, rgb(5, 41, 119) 0%, rgba(5, 41, 119, 0.5) 40%, rgba(5, 41, 119, 0) 100%)'
                                                }}
                                            />
                                        </div> */}


                                            <div className="bio-profile-bg">
                                                {coverImage && (
                                                    <img
                                                        src={coverImage}
                                                        alt="media_image"
                                                        className="new-bio-bg"
                                                    />
                                                )}
                                                {color && coverImage ? (
                                                    <>
                                                        <div
                                                            className="profile-color"
                                                            style={{
                                                                background: `linear-gradient(to top, ${color
                                                                    } 0%, ${color}80 40%, ${color
                                                                    }00 100%)`,
                                                            }}
                                                        ></div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div
                                                            className="profile-color"
                                                            style={{
                                                                background: `linear-gradient(to top, #052977 0%, #052977 4%, transparent 100%)`,
                                                            }}
                                                        ></div>
                                                    </>
                                                )}
                                            </div>


                                        </div>
                                        <div className="left-content-main">

                                            <Box className="w-100">
                                                <nav className="user-list-main" aria-label="main mailbox folders">
                                                    <List>
                                                        <Divider />
                                                        <ListItem disablePadding selected={active === 1 ? true : false}>
                                                            <ListItemButton onClick={() => { setActive(1) }}>
                                                                <ListItemText primary="Information" className="user-list-txt" />
                                                            </ListItemButton>
                                                        </ListItem>
                                                        <Divider />
                                                        {
                                                            userType === "brand" &&
                                                            <>
                                                                <ListItem disabled={deleted} disablePadding selected={active === 22 ? true : false}>
                                                                    <ListItemButton disabled={deleted} onClick={() => { setActive(22) }}>
                                                                        <ListItemText primary="Search Tags" className="user-list-txt" />
                                                                    </ListItemButton>
                                                                </ListItem>
                                                                <Divider />
                                                                <ListItem disabled={deleted} disablePadding selected={active === 19 ? true : false}>
                                                                    <ListItemButton disabled={deleted} onClick={() => { setActive(19) }}>
                                                                        <ListItemText primary="Balance" className="user-list-txt" />
                                                                    </ListItemButton>
                                                                </ListItem>
                                                                <Divider />
                                                                <ListItem disabled={deleted} disablePadding selected={active === 2 ? true : false}>
                                                                    <ListItemButton disabled={deleted} onClick={() => { setActive(2) }}>
                                                                        <ListItemText primary="Package" className="user-list-txt" />
                                                                    </ListItemButton>
                                                                </ListItem>
                                                                <Divider />
                                                                <ListItem disabled={deleted} disablePadding selected={active === 14 ? true : false}>
                                                                    <ListItemButton disabled={deleted} onClick={() => { setActive(14) }}>
                                                                        <ListItemText primary="Category Setup" className="user-list-txt" />
                                                                    </ListItemButton>
                                                                </ListItem>
                                                                <Divider />
                                                                <ListItem disabled={deleted} disablePadding selected={active === 18 ? true : false}>
                                                                    <ListItemButton disabled={deleted} onClick={() => { setActive(18) }}>
                                                                        <ListItemText primary="Discounts" className="user-list-txt" />
                                                                    </ListItemButton>
                                                                </ListItem>
                                                                <Divider />
                                                                <ListItem disablePadding selected={active === 3 ? true : false}>
                                                                    <ListItemButton onClick={() => { setActive(3) }}>
                                                                        <ListItemText primary="Total Sales" className="user-list-txt" />
                                                                    </ListItemButton>
                                                                </ListItem>
                                                                {/* <Divider /> */}
                                                                {/* <ListItem disabled={deleted} disablePadding selected={active === 4 ? true : false}>
                                                                <ListItemButton disabled={deleted} onClick={() => { setActive(4) }}>
                                                                    <ListItemText primary={"Referral Sales"} className="user-list-txt" />
                                                                </ListItemButton>
                                                            </ListItem>
                                                            <Divider />
                                                            <ListItem disabled={deleted} disablePadding selected={active === 12 ? true : false}>
                                                                <ListItemButton disabled={deleted} onClick={() => { setActive(12) }}>
                                                                    <ListItemText primary={"Review Salessss"} className="user-list-txt" />
                                                                </ListItemButton>
                                                            </ListItem> */}
                                                                <Divider />
                                                                {/* <ListItem disabled={deleted} disablePadding selected={active === 5 ? true : false}>
                                                                <ListItemButton disabled={deleted} onClick={() => { setActive(5) }}>
                                                                    <ListItemText primary={"Market Fees"} className="user-list-txt" />
                                                                </ListItemButton>
                                                            </ListItem>
                                                            <Divider /> */}
                                                                <ListItem disabled={deleted} disablePadding selected={active === 6 ? true : false}>
                                                                    <ListItemButton disabled={deleted} onClick={() => {
                                                                        setActive(6)
                                                                    }}>
                                                                        <ListItemText primary="ORME Fee setup" className="user-list-txt" />
                                                                    </ListItemButton>
                                                                </ListItem>
                                                                <Divider />
                                                                <ListItem disabled={deleted} disablePadding selected={active === 17 ? true : false}>
                                                                    <ListItemButton disabled={deleted} onClick={() => {
                                                                        setActive(17)
                                                                    }}>
                                                                        <ListItemText primary={"Shipping Methods"} className="user-list-txt" />
                                                                    </ListItemButton>
                                                                </ListItem>
                                                                <Divider />
                                                                <ListItem disabled={deleted} disablePadding selected={active === 7 ? true : false}>
                                                                    <ListItemButton disabled={deleted} onClick={() => {
                                                                        setActive(7)
                                                                    }}>
                                                                        <ListItemText primary="Links" className="user-list-txt" />
                                                                    </ListItemButton>
                                                                </ListItem>
                                                                <Divider />
                                                                <ListItem disabled={deleted} disablePadding selected={active === 15 ? true : false}>
                                                                    <ListItemButton disabled={deleted} onClick={() => {
                                                                        setActive(15)
                                                                    }}>
                                                                        <ListItemText primary="Agreement" className="user-list-txt" />
                                                                    </ListItemButton>
                                                                </ListItem>
                                                                <Divider />
                                                                <ListItem disabled={deleted} disablePadding selected={active === 16 ? true : false}>
                                                                    <ListItemButton onClick={() => {
                                                                        setActive(16)
                                                                    }}>
                                                                        <ListItemText primary="Payment Methods" className="user-list-txt" />
                                                                    </ListItemButton>
                                                                </ListItem>
                                                                <Divider />
                                                            </>
                                                        }
                                                        {userType === "influencer" &&
                                                            <>
                                                                <ListItem disabled={deleted} disablePadding selected={active === 8 ? true : false}>
                                                                    <ListItemButton disabled={deleted} onClick={() => {
                                                                        setActive(8)
                                                                    }}>

                                                                        <ListItemText primary="Purchases" className="user-list-txt" />
                                                                    </ListItemButton>
                                                                </ListItem>
                                                                <Divider />
                                                                <ListItem disabled={deleted} disablePadding selected={active === 9 ? true : false}>
                                                                    <ListItemButton disabled={deleted} onClick={() => {
                                                                        setActive(9)
                                                                    }}>
                                                                        <ListItemText primary="Referral Sales" className="user-list-txt" />
                                                                    </ListItemButton>
                                                                </ListItem>
                                                                <Divider />
                                                                {
                                                                    userType === "influencer" &&
                                                                    <>
                                                                        <ListItem disabled={deleted} disablePadding selected={active === 13 ? true : false}>
                                                                            <ListItemButton disabled={deleted} onClick={() => {
                                                                                setActive(13)
                                                                            }}>
                                                                                <ListItemText primary="Creator Sales" className="user-list-txt" />
                                                                            </ListItemButton>
                                                                        </ListItem>
                                                                        <Divider />
                                                                    </>
                                                                }
                                                                <ListItem disabled={deleted} disablePadding selected={active === 10 ? true : false}>
                                                                    <ListItemButton disabled={deleted} onClick={() => {
                                                                        setActive(10)
                                                                    }}>
                                                                        <ListItemText primary="Earnings" className="user-list-txt" />
                                                                    </ListItemButton>
                                                                </ListItem>
                                                                <Divider />
                                                                <ListItem disabled={deleted} disablePadding selected={active === 11 ? true : false}>
                                                                    <ListItemButton onClick={() => {
                                                                        setActive(11)
                                                                    }}>
                                                                        <ListItemText primary="Withdrawal" className="user-list-txt" />
                                                                    </ListItemButton>
                                                                </ListItem>
                                                                <Divider />
                                                                <ListItem disabled={deleted} disablePadding selected={active === 17 ? true : false}>
                                                                    <ListItemButton disabled={deleted} onClick={() => {
                                                                        setActive(17)
                                                                    }}>
                                                                        <ListItemText primary={"Shipping Addresses"} className="user-list-txt" />
                                                                    </ListItemButton>
                                                                </ListItem>
                                                                <Divider />
                                                                <ListItem disabled={deleted} disablePadding selected={active === 16 ? true : false}>
                                                                    <ListItemButton onClick={() => {
                                                                        setActive(16)
                                                                    }}>
                                                                        <ListItemText primary="Payment Methods" className="user-list-txt" />
                                                                    </ListItemButton>
                                                                </ListItem>
                                                                <Divider />
                                                                {/* <Divider />
                                                            <ListItem disabled={deleted} disablePadding selected={active === 9 ? true : false}>
                                                                <ListItemButton disabled={deleted} onClick={() => {
                                                                    setActive(9)
                                                                }}>
                                                                    <ListItemText primary="Cashback" className="user-list-txt" />
                                                                </ListItemButton>
                                                            </ListItem> */}
                                                                {/* <Divider /> */}
                                                            </>
                                                        }
                                                        {
                                                            userType === "agency" &&
                                                            <>
                                                                <ListItem disabled={deleted} disablePadding selected={active === 20 ? true : false}>
                                                                    <ListItemButton disabled={deleted} onClick={() => {
                                                                        setActive(20)
                                                                    }}>
                                                                        <ListItemText primary="Talent List" className="user-list-txt" />
                                                                    </ListItemButton>
                                                                </ListItem>
                                                                <Divider />
                                                                <ListItem disabled={deleted} disablePadding selected={active === 21 ? true : false}>
                                                                    <ListItemButton disabled={deleted} onClick={() => {
                                                                        setActive(21)
                                                                    }}>
                                                                        <ListItemText primary="Total Sales" className="user-list-txt" />
                                                                    </ListItemButton>
                                                                </ListItem>
                                                                <Divider />
                                                                <ListItem disabled={deleted} disablePadding selected={active === 10 ? true : false}>
                                                                    <ListItemButton disabled={deleted} onClick={() => {
                                                                        setActive(10)
                                                                    }}>
                                                                        <ListItemText primary="Earnings" className="user-list-txt" />
                                                                    </ListItemButton>
                                                                </ListItem>
                                                                <Divider />
                                                                <ListItem disabled={deleted} disablePadding selected={active === 11 ? true : false}>
                                                                    <ListItemButton disabled={deleted} onClick={() => {
                                                                        setActive(11)
                                                                    }}>
                                                                        <ListItemText primary="Withdrawal" className="user-list-txt" />
                                                                    </ListItemButton>
                                                                </ListItem>
                                                                <Divider />
                                                                <ListItem disabled={deleted} disablePadding selected={active === 15 ? true : false}>
                                                                    <ListItemButton disabled={deleted} onClick={() => {
                                                                        setActive(15)
                                                                    }}>
                                                                        <ListItemText primary="Agreement" className="user-list-txt" />
                                                                    </ListItemButton>
                                                                </ListItem>
                                                                <Divider />

                                                                {/* <ListItem disablePadding selected={active === 3 ? true : false}>
                                                                <ListItemButton onClick={() => { setActive(3) }}>
                                                                    <ListItemText primary="Total Sales" className="user-list-txt" />
                                                                </ListItemButton>
                                                            </ListItem>
                                                            <Divider /> */}
                                                            </>
                                                        }
                                                    </List>

                                                </nav>
                                            </Box>
                                        </div>

                                    </Col>

                                    <Col sm={8} xl={10} className="right-column">
                                        <Row className='user-name-area bg-white'>
                                            <Col xl={12} lg={12} className="d-flex align-items-center justify-content-between">
                                                <Box className="d-flex align-items-center">
                                                    {/* <Avatar
                                                    variant="rounded"
                                                    alt={`${userName}-image`}
                                                    src={userImage}
                                                    sx={{ width: 30, height: 30, border: "1px solid gray", marginRight: "5px" }}
                                                /> */}
                                                    <AccountCircleIcon className='user-icon mb-0' />
                                                    <Typography className='fw-normal mb-0' color="primary" variant="h6" gutterBottom component="div">
                                                        {userName}
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    {from !== "withdraw" &&
                                                        <IconButton
                                                            onClick={() => {
                                                                addMinimize({
                                                                    userName,
                                                                    id: currentUser
                                                                })
                                                                setActive(1)
                                                                close()
                                                            }}
                                                            aria-label="delete">
                                                            <RemoveIcon />
                                                        </IconButton>
                                                    }


                                                    <IconButton
                                                        onClick={() => {
                                                            setActive(1)
                                                            close()
                                                        }}
                                                        aria-label="delete">
                                                        <CancelIcon color="error" />
                                                    </IconButton>
                                                </Box>
                                            </Col>
                                        </Row>
                                        <div className="right-content-main">
                                            <Conditional nowGet={nowGet} currentCustomerId={currentCustomerId} deleted={deleted} active={active} currentUser={currentUser} userType={userType} close={() => close()} />
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Box>
                    </Slide>
                </Modal>
            </div >
        </>



    );
}

function mapStateToProps({ accountDetail, editUser }) {
    return { accountDetail, editUser }
}

export default connect(mapStateToProps, minimizeActions)(UserModal)