import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import { Link } from "react-router-dom"
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { PaymentsSharp } from '@mui/icons-material';
import FollowerReqIcon from '@mui/icons-material/Receipt';
import DescriptionIcon from '@mui/icons-material/Description';
import NotificationsIcon from '@mui/icons-material/Notifications';
import BackpackIcon from '@mui/icons-material/Backpack';
import CategoryIcon from '@mui/icons-material/Category';
import { useLocation } from 'react-router-dom'
import defaultImg from "../assets/images/default.jpg";
import { connect, useDispatch } from "react-redux"
import * as authActions from "../store/actions/auth"
import LogoutIcon from '@mui/icons-material/Logout';
import LockIcon from '@mui/icons-material/Lock';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import CachedIcon from '@mui/icons-material/Cached';
import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import "./slide.css"
import { useHistory } from "react-router-dom";
import CampaignIcon from '@mui/icons-material/Campaign';
import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';
import PublicIcon from '@mui/icons-material/Public';
import RefreshIcon from '@mui/icons-material/Refresh';
// import LogoImage from "../assets/images/newLogo.svg"
import SettingsIcon from '@mui/icons-material/Settings';
import VideoSettingsIcon from '@mui/icons-material/VideoSettings';
// import LogoImage from "../assets/images/ORME2.svg"
import LogoImage from "../assets/images/ORME3.svg"

import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SubtitlesIcon from '@mui/icons-material/Subtitles';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import CalculateIcon from '@mui/icons-material/Calculate';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import PercentIcon from '@mui/icons-material/Percent';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import MoneyIcon from '@mui/icons-material/Money';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import PaidIcon from '@mui/icons-material/Paid';
import StarsIcon from '@mui/icons-material/Stars';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';
import SchemaIcon from '@mui/icons-material/Schema';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import HomeIcon from '@mui/icons-material/Home';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AddCardIcon from '@mui/icons-material/AddCard';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import EventIcon from '@mui/icons-material/Event';
import PeopleIcon from '@mui/icons-material/People';
import PaymentIcon from '@mui/icons-material/Payment';
import SpeedIcon from '@mui/icons-material/Speed';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import SecurityIcon from '@mui/icons-material/Security';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import ArticleIcon from '@mui/icons-material/Article';
import PriceChangeIcon from '@mui/icons-material/PriceChange'
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import GroupsIcon from '@mui/icons-material/Groups';
import InventoryIcon from '@mui/icons-material/Inventory';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import MessageIcon from '@mui/icons-material/Message';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import CommentIcon from '@mui/icons-material/Comment';
import DiscountIcon from '@mui/icons-material/Discount';
const drawerWidth = 240;
function ResponsiveDrawer({ window, logOut, children, user, refresh }) {
  const history = useHistory()
  const [selected, setSelected] = React.useState("")
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState({});
  const location = useLocation();
  const path = location.pathname
  React.useEffect(() => {
    const finalPath = path.slice(1, path.length)
    setSelected(finalPath)
  }, [path])

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const listFinance = [
    {
      key: "dash",
      label: "Dashboard",
      icon: DashboardIcon,
      route: "/dashboard"
    },


    {
      key: "reports",
      label: "Sales Report",
      icon: StackedLineChartIcon,
      items: [
        { key: "totalSales", label: "Total Sales", icon: StackedLineChartIcon, fr: "totalSales" },
      ]
    },
    {
      key: "payouts",
      label: "Payout",
      icon: PaymentIcon,
      items: [
        { key: "requests", label: "User Withdraw Requests", icon: PaymentIcon, fr: "requests" },
      ]
    },


  ]


  const lists = [
    {
      key: "dashboard",
      label: "Dashboard",
      icon: DashboardIcon,
      route: "/dashboard"
    },

    {
      key: "crm",
      label: "CRM",
      icon: ManageAccountsIcon,
      items: [
        { key: "brand", label: "Brands", icon: BrandingWatermarkIcon, fr: "brand" },
        { key: "influencer", label: "Users", icon: PeopleAltIcon, fr: "influencer" },
        // { key: "userDetails", label: "User Details", icon: PeopleAltIcon, fr: "userDetails" },
        { key: "house", label: "House ", icon: CampaignIcon, fr: "house" },
        { key: "agency", label: "Agencies", icon: GroupsIcon, fr: "agency" },
        // { key: "customer", label: "Shoppers", icon: PeopleAltIcon, fr: "cust" },
      ]
    },
    {
      key: "category",
      label: "Category",
      icon: CategoryIcon,
      items: [
        { key: "department", label: "Department", icon: SchemaIcon, fr: "department" },
        { key: "category", label: "Category", icon: SchemaIcon, fr: "category" },
        { key: "subCategory", label: "Sub Category", icon: SchemaIcon, fr: "subCategory" },
      ]
    },
    {
      key: "promotions",
      label: "Promotions",
      icon: CampaignIcon,
      items: [
        // { key: "adManager", label: "Ad Manager", icon: ManageHistoryIcon, fr: "adManager" },
        { key: "banners", label: "Banners", icon: ViewCarouselIcon, fr: "banners" },
        { key: "boostedVideos", label: "Boosted Videos", icon: TrendingUpIcon, fr: "boostedVideos" },
        { key: "promotionCriteria", label: "Promotion Criteria", icon: CampaignIcon, fr: "promotionCriteria" },
        { key: "promotion", label: "Promotion Sales", icon: CampaignIcon, fr: "promotion" },
        { key: "paymentReport", label: "Payment Report", icon: StackedLineChartIcon, fr: "paymentReport" },
      ]

    },
    {
      key: "content",
      label: "Content Management",
      icon: VideoSettingsIcon,
      items: [
        { key: "moderation", label: "Moderation Level", icon: SecurityIcon, fr: "moderation" },
        { key: "manageReview", label: "Reviews", icon: VideoSettingsIcon, fr: "manageReview" },
        { key: "comments", label: "Comments", icon: CommentIcon, fr: "comments" },
        { key: "banusers", label: "Ban Users", icon: PeopleAltIcon, fr: "banusers" },
      ]
    },
    {
      key: "productManagement",
      label: "Management",
      icon: ManageSearchIcon,
      items: [
        // { key: "packages", label: "Packages", icon: BackpackIcon, fr: "pack" },
        // { key: "categories", label: "Categories", icon: CategoryIcon, fr: "cate" },
        // { key: "newcategories", label: "Categories", icon: CategoryIcon, fr: "newc" },
        // { key: "subcategories", label: "Sub Categories", icon: SchemaIcon, fr: "subc" },
        // { key: "notification", label: "Notifications", icon: NotificationsIcon, fr: "notification" },
        { key: "messages", label: "Notification Messages", icon: MessageIcon, fr: "messages" },
        // { key: "promocodes", label: "Promocodes", icon: ConfirmationNumberIcon, fr: "prom" },
        { key: "country", label: "Country", icon: PublicIcon, fr: "country" },
        // { key: "userCategories", label: "Custom Categories", icon: SettingsIcon, fr: "user" },
      ]
    },
    {
      key: "reports",
      label: "Sales Report",
      icon: StackedLineChartIcon,
      items: [
        { key: "totalSales", label: "Total Sales", icon: StackedLineChartIcon, fr: "totalSales" },
      ]
    },
    // {
    //   key: "promotion",
    //   label: "Promotion Sales",
    //   icon: CampaignIcon,
    //   route: "/promotion"
    // },
    {
      key: "returns",
      label: "Returns",
      icon: CurrencyExchangeIcon,
      route: "/returns"
    },
    // {
    //   key: "feed",
    //   label: "Feed Stats",
    //   icon: ShowChartIcon,
    //   route: "/feedStats"
    // },
    {
      key: "stats",
      label: "Stats",
      icon: ShowChartIcon,
      items: [
        { key: "userRegisterStats", label: "Link in Bio Stats", icon: ShowChartIcon, fr: "userRegisterStats" },
        { key: "userStats", label: "User Stats", icon: ShowChartIcon, fr: "userStats" },
        { key: "brandStats", label: "Brand Stats", icon: ShowChartIcon, fr: "brandStats" },
        { key: "videoStats", label: "Video Stats", icon: ShowChartIcon, fr: "videoStats" },
      ]
    },

    {
      key: "payouts",
      label: "Payout",
      icon: PaymentIcon,
      items: [
        { key: "requests", label: "User Withdraw Requests", icon: PaymentIcon, fr: "requests" },
        { key: "release", label: "User Payout Release", icon: CreditScoreIcon, fr: "release" },
        { key: "payoutSetting", label: "User Payout Settings", icon: SettingsIcon, fr: "payoutSetting" },
        { key: "agencyRequests", label: "Agency Withdraw Requests", icon: PaymentIcon, fr: "agencyRequests" },
        { key: "agencyRelease", label: "Agency Payout Release", icon: CreditScoreIcon, fr: "agencyRelease" },
        // { key: "agencyPayoutSetting", label: "Agency Payout Settings", icon: SettingsIcon, fr: "agencyPayoutSetting" },

      ]
    },
    {
      key: "feedSetting",
      label: "Feed Setting",
      icon: DynamicFeedIcon,
      items: [
        // { key: "reviewFeed", label: "Review Feed", icon: VideoSettingsIcon, fr: "reviewFeed" },
        { key: "followingFeed", label: "Following Feed", icon: VideoSettingsIcon, fr: "followingFeed" },
        { key: "forYouFeed", label: "For You Feed", icon: VideoSettingsIcon, fr: "forYouFeed" },
      ]
    },
    {
      key: "discover",
      label: "Discover",
      icon: PublicIcon,
      route: "/discover"
    },
    {
      key: "discounts",
      label: "Discounts",
      icon: DiscountIcon,
      route: "/discounts"
    },
    {
      key: "tags",
      label: "Trending Search",
      icon: LocalOfferIcon,
      route: "/tags"
    },
    {
      key: "shipping",
      label: "Shipping Methods",
      icon: LocalShippingIcon,
      route: "/shipping"
    },
    {
      key: "pricePoints",
      label: "Price Points",
      icon: PriceChangeIcon,
      route: "/pricePoints"
    },
    {
      key: "logs",
      label: "Logs",
      icon: DescriptionIcon,
      route: "/logs"
    },
    {
      key: "micro",
      label: "Micro Services",
      icon: DynamicFeedIcon,
      route: "/micro"
    },
    {
      key: "adminControl",
      label: "Admin Control",
      icon: AdminPanelSettingsIcon,
      route: "/adminControl"
    },
    {
      key: "products",
      label: "Products",
      icon: InventoryIcon,
      route: "/products"
    },
    // {
    //   key: "userShop",
    //   label: "User Shop Filters",
    //   icon: GroupsIcon,
    //   route: "/userShop"
    // },

    {
      key: "introVideo",
      label: "Intro Video",
      icon: OndemandVideoIcon,
      route: "/introVideo"
    },
    {
      key: "rank",
      label: "Rank",
      icon: AdminPanelSettingsIcon,
      route: "/rank"
    },
    {
      key: "settings",
      label: "Settings",
      icon: SettingsIcon,
      items: [
        { key: "appSetting", label: "App Settings", icon: AppSettingsAltIcon, fr: "appSetting" },
        { key: "code", label: "Master Key", icon: VpnKeyIcon, fr: "code" },
        { key: "boostSetting", label: "Boost Settings", icon: TrendingUpIcon, fr: "boostSetting" },
        { key: "feesSetting", label: "Fees Setting", icon: SettingsIcon, fr: "feesSetting" },
        { key: "videoSetting", label: "Video Settings", icon: VideoSettingsIcon, fr: "videoSetting" },
        { key: "followerLimit", label: "Follower Settings", icon: PeopleAltIcon, fr: "followerLimit" },
        { key: "emailSetting", label: "Email Settings", icon: ForwardToInboxIcon, fr: "emailSetting" },
        { key: "terms", label: "Terms & Conditions", icon: ArticleIcon, fr: "terms" },
      ]
    },
    // {
    //   key: "pay",
    //   label: "Payouts",
    //   icon: PaymentIcon,
    //   route: "/payout"
    // },
    // {
    //   key: "adMa",
    //   label: "Ad Manager",
    //   icon: ManageHistoryIcon,
    //   route: "/adManager"
    // },
    // {
    //   key: "vide",
    //   label: "Video Boost",
    //   icon: TrendingUpIcon,
    //   route: "/videoBoost"
    // },

    // {
    //   key: "test",
    //   label: "Test",
    //   icon: ManageHistoryIcon,
    //   route: "/test"
    // },


    // {
    //   key: "even",
    //   label: "Events",
    //   icon: EventIcon,
    //   route: "/events"
    // },


    // {
    //   key: "feed",
    //   label: "Feed Setting",
    //   icon: VideoSettingsIcon,
    //   route: "/feedSetting"
    // },


    // {
    //   key: "appS",
    //   label: "App Setting",
    //   icon: AppSettingsAltIcon,
    //   route: "/appSetting"
    // },

    // {
    //   key: "accounting",
    //   label: "Accounting",
    //   icon: CalculateIcon,
    //   items: [
    //     { key: "orders", label: "Orders", icon: ShoppingBasketIcon, fr: "orde" },
    //     { key: "referral", label: "Referral Stats", icon: PeopleIcon, fr: "refe" },
    //     { key: "marketfee", label: "Market Fees", icon: AttachMoneyIcon, fr: "mark" },
    //     { key: "payout", label: "Payout", icon: PaymentIcon, fr: "payo" },
    //     // { key: "transaction", label: "Transaction", icon: LibraryBooksIcon, fr: "tran" },
    //     // { key: "deposit", label: "Deposit", icon: AddCardIcon, fr: "depo" },
    //     // { key: "sales", label: "Sales", icon: PaidIcon, fr: "sale" },
    //     // { key: "retSakes", label: "Sales Return", icon: RestartAltIcon, fr: "retS" },
    //     // { key: "purchase", label: "Purchases", icon: ShoppingCartIcon, fr: "purc" },
    //     // { key: "retPurchase", label: "Purchase Return", icon: CurrencyExchangeIcon, fr: "retP" },
    //     // { key: "cashback", label: "Cashback", icon: LocalAtmIcon, fr: "cash" },
    //     // { key: "commission", label: "Commission", icon: AttachMoneyIcon, fr: "comm" },
    //     // { key: "withdrawal", label: "Withdrawal", icon: AccountBalanceWalletIcon, fr: "with" },
    //     // { key: "ledger", label: "Ledger", icon: LibraryBooksIcon, fr: "ledg" },
    //     // { key: "kbcommission", label: "KB Commission", icon: PercentIcon, fr: "kbco" },
    //     // { key: "kbearning", label: "KB Earning", icon: MoneyIcon, fr: "kbea" },
    //     // { key: "kbbalance", label: "KB Balance", icon: PaidIcon, fr: "kbba" },
    //   ]
    // },
    // {
    //   key: "marketing",
    //   label: "Marketing",
    //   icon: CampaignIcon,
    //   items: [
    //     { key: "topBrand", label: "Top Brand", icon: BrandingWatermarkIcon, fr: "topB" },
    //     { key: "topInfluencer", label: "Top Influencer", icon: CampaignIcon, fr: "topI" },
    //     { key: "topCustomer", label: "Top Customer", icon: PeopleAltIcon, fr: "topC" },
    //   ]
    // },
    // {
    //   key: "sale",
    //   label: "Sales",
    //   icon: PaidIcon,
    //   items: [
    //     { key: "bSale", label: "Brand Sales", icon: LocalAtmIcon, fr: "bSal" },
    //     { key: "iSale", label: "Influencer Sales", icon: AttachMoneyIcon, fr: "iSal" },
    //     { key: "cSale", label: "Customer Sales", icon: PaidOutlinedIcon, fr: "cSal" },
    //   ]
    // },
    // {
    //   key: "adManage",
    //   label: "Ad Manage",
    //   icon: ManageHistoryIcon,
    //   items: [
    //     { key: "adManage", label: "Home Page", icon: HomeIcon, fr: "adMa" },
    //     // { key: "adManage", label: "Home Page", icon: HomeIcon, fr: "adMa" },
    //   ]
    // }
    // {
    //   key: "orde",
    //   label: "Order Details",
    //   icon: ShoppingBasketIcon,
    //   route: "/orders"
    // },
    // {
    //   key: "feat",
    //   label: "Brand Featured",
    //   icon: FeaturedPlayListIcon,
    //   route: "/featured"
    // },
    // {
    //   key: "catF",
    //   label: "Category Featured",
    //   icon: CategoryIcon,
    //   route: "/catFeatured"
    // },
    // {
    //   key: "catP",
    //   label: "Popular Category",
    //   icon: StarsIcon,
    //   route: "/catPopular"
    // },
    // {
    //   key: "foll",
    //   label: "Influencer Requests",
    //   icon: FollowerReqIcon,
    //   route: "/followersRequests"
    // },
    // {
    //   key: "adMa",
    //   label: "Ad Manager",
    //   icon: ManageHistoryIcon,
    //   route: "/adManager"
    // },
    // {
    //   key: "adPr",
    //   label: "Ad Price Manager",
    //   icon: ManageHistoryIcon,
    //   route: "/adPrice"
    // },
    // {
    //   key: "mana",
    //   label: "Access",
    //   icon: LockIcon,
    //   route: "/manageAccess"
    // },
    // {
    //   key: "logs",
    //   label: "Logs",
    //   icon: DescriptionIcon,
    //   route: "/logs"
    // },
    // {
    //   key: "camp",
    //   label: "Brand-Campaign",
    //   icon: BrandingWatermarkIcon,
    //   route: "/campaign"
    // },
    // {
    //   key: "infl",
    //   label: "Influencer-Campaign",
    //   icon: CampaignIcon,
    //   route: "/influencer"
    // },

    // {
    //   key: "auth",
    //   label: "Auth Permissions",
    //   icon: EnhancedEncryptionIcon,
    //   route: "/authPer"
    // },
    // {
    //   key: "adm",
    //   label: "Administrator",
    //   icon: AdminPanelSettingsIcon,
    //   items: [
    //     { key: "users", label: "Users", icon: PersonAddIcon, fr: "user" },
    //   ]
    // },
  ];
  React.useEffect(() => {
    let url = path.slice(1, path.length);
    if (url === "brand" || url === "influencer" || url === "house" || url.includes('brand/edit/') || url === "agency" || url === "userDetails") {
      setOpen1({ crm: true })
    } else if (url === "department" || url === "category" || url === "subCategory") {
      setOpen1({ category: true })
    } else if (url === "notification" || url === "country" || url === "messages") {
      setOpen1({ productManagement: true })
    } else if (url === "requests" || url === "release" || url === "payoutSetting" || url === "agencyRequests" || url === "agencyRelease" || url === "agencyPayoutSetting") {
      setOpen1({ payouts: true })
    } else if (url === "appSetting" || url === "boostSetting" || url === "feesSetting" || url === "videoSetting" || url === "followerSetting" || url === "emailSetting" || url === 'terms' || url === "code") {
      setOpen1({ settings: true })
    } else if (url === "adManager" || url === "boostedVideos" || url === "banners" || url === 'promotionCriteria' || url === "promotion" || url === "paymentReport") {
      setOpen1({ promotions: true })
    } else if (url === "reviewFeed" || url === "showFeed" || url === "followingFeed" || url === "forYouFeed") {
      setOpen1({ feedSetting: true })
    } else if (url === "userStats" || url === "videoStats" || url === "brandStats" || url === "userRegisterStats") {
      setOpen1({ stats: true })
    } else if (url === "manageReview" || url === "moderation" || url === "comments" || url === "banusers") {
      setOpen1({ content: true })
    } else if (url === "totalSales") {
      setOpen1({ reports: true })
    } else {
      setOpen1({ [url]: !open1[url] })
    }
  }, [])
  const dispatch = useDispatch()

  const click = (key) => {
    setOpen1({ [key]: !open1[key] })
  }
  const drawer = (
    <div>
      <div className='admin-profile-box'>
        <img className='admin-profile-pic'
          src={defaultImg}
        />
        <h6>{user.message?.name}</h6>
        <p>{user.message?.email}</p>
      </div>

      <List className='dashboard-menu dash-menu-main' onClick={() => dispatch({
        type: "EDITUSER",
        payload: null
      })} >
        {
          (user?.message?.email !== "finance@ormelive.com") ?
            lists.map(({ key, label, icon: Icon, items, route }) => {
              const open = open1[key] || false;
              return (
                <div key={key}>
                  {!items ?
                    <>
                      <Link className="link" to={route}>
                        <ListItem
                          className='mylist'
                          onClick={() => {
                            setMobileOpen(false)
                            click(key)
                            setSelected(key)
                          }}
                          selected={open === "dash" ? true : false} disablePadding
                        >
                          <ListItemButton className='dash-menu-button'>
                            <ListItemIcon className='dash-menu-icon'>
                              <Icon color={open ? "primary" : "inherit"} />
                            </ListItemIcon>
                            <ListItemText primary={label} className='dash-menu-txt' />
                          </ListItemButton>
                        </ListItem>
                      </Link>
                    </>
                    :
                    <>
                      <ListItem
                        onClick={() => {
                          click(key)
                        }}
                        disablePadding>
                        <ListItemButton className='dash-menu-button' >
                          <ListItemIcon className='dash-menu-icon'>
                            <Icon color={open ? "primary" : "inherit"} />
                          </ListItemIcon>
                          <ListItemText
                            className='dash-menu-txt'
                            primary={label}
                          />
                          {open ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                      </ListItem>
                      <Collapse className='drop-main' in={open} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          {items.map(({ key: childKey, label: childLabel, icon: ChildIcon, fr: ChildFr }) => {
                            let check = selected.split('/')[0]
                            return (
                              <Link key={childKey} className='link' to={`/${childKey}`}>
                                <ListItem
                                  onClick={() => {
                                    setMobileOpen(false)
                                  }}
                                  key={childKey}
                                  selected={selected === ChildFr ? true : false}
                                  disablePadding
                                >
                                  <ListItemButton sx={{ pl: 4 }} className='dash-menu-button second-level'>
                                    <ListItemIcon className={key === "sale" ? " dash-menu-icon check" : "dash-menu-icon"}>
                                      {/* <ChildIcon color={selected === ChildFr ? "primary" : "inherit"} /> */}
                                      <ChildIcon color={((selected === ChildFr) || (check === ChildFr)) ? "primary" : "inherit"} />
                                    </ListItemIcon>
                                    <ListItemText primary={childLabel} className='dash-menu-txt' />
                                  </ListItemButton>
                                </ListItem>
                              </Link>
                            )
                          })}
                        </List>
                      </Collapse>
                    </>
                  }
                </div>
              )
            })
            :
            listFinance.map(({ key, label, icon: Icon, items, route }) => {
              const open = open1[key] || false;
              return (
                <div key={key}>
                  {!items ?
                    <>
                      <Link className="link" to={route}>
                        <ListItem
                          className='mylist'
                          onClick={() => {
                            setMobileOpen(false)
                            click(key)
                            setSelected(key)
                          }}
                          selected={open === "dash" ? true : false} disablePadding
                        >
                          <ListItemButton className='dash-menu-button'>
                            <ListItemIcon className='dash-menu-icon'>
                              <Icon color={open ? "primary" : "inherit"} />
                            </ListItemIcon>
                            <ListItemText primary={label} className='dash-menu-txt' />
                          </ListItemButton>
                        </ListItem>
                      </Link>
                    </>
                    :
                    <>
                      <ListItem
                        onClick={() => {
                          click(key)
                        }}
                        disablePadding>
                        <ListItemButton className='dash-menu-button' >
                          <ListItemIcon className='dash-menu-icon'>
                            <Icon color={open ? "primary" : "inherit"} />
                          </ListItemIcon>
                          <ListItemText
                            className='dash-menu-txt'
                            primary={label}
                          />
                          {open ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                      </ListItem>
                      <Collapse className='drop-main' in={open} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          {items.map(({ key: childKey, label: childLabel, icon: ChildIcon, fr: ChildFr }) => {
                            let check = selected.split('/')[0]
                            return (
                              <Link key={childKey} className='link' to={`/${childKey}`}>
                                <ListItem
                                  onClick={() => {
                                    setMobileOpen(false)
                                  }}
                                  key={childKey}
                                  selected={selected === ChildFr ? true : false}
                                  disablePadding
                                >
                                  <ListItemButton sx={{ pl: 4 }} className='dash-menu-button second-level'>
                                    <ListItemIcon className={key === "sale" ? " dash-menu-icon check" : "dash-menu-icon"}>
                                      {/* <ChildIcon color={selected === ChildFr ? "primary" : "inherit"} /> */}
                                      <ChildIcon color={((selected === ChildFr) || (check === ChildFr)) ? "primary" : "inherit"} />
                                    </ListItemIcon>
                                    <ListItemText primary={childLabel} className='dash-menu-txt' />
                                  </ListItemButton>
                                </ListItem>
                              </Link>
                            )
                          })}
                        </List>
                      </Collapse>
                    </>
                  }
                </div>
              )
            })
        }
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex', backgroundColor: '#f7f7f7', height: '100%' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
          backgroundColor: 'white'
        }}
      >
        <Toolbar>
          <IconButton
            className='hamburger-btn'
            color="primary"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Link to="/" className="logo">
            <img
              alt="logo"
              src={LogoImage}
              className="logo-image"
            //className="img-fluid"
            />
          </Link>
          <Box className='icon-style-for-mob' sx={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end' }}>
            {/* {
              selected == "dash" ? (
                <Button
                  // onClick={refresh}
                  endIcon={<RefreshIcon />}
                  className="btn-width btn-gen-margin button-icon-small" color="success" variant="contained" disableElevation><span className='d-none d-sm-block'>Refresh</span></Button>
              ) : null
            } */}
            <Button
              onClick={() => {
                logOut()
                history.push('/login')
              }}
              endIcon={<LogoutIcon />}
              className="btn-width btn-gen-margin mr-0 button-icon-small" color="primary" variant="contained" disableElevation><span className='d-none d-sm-block'>Log Out</span></Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { sm: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          className='sidebar-main'
          variant="permanent"
          sx={{
            display: { xs: 'none', md: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: selected === "acco" ? 0 : 0, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

function mapStateToProps({ user }) {
  return { user }
}

export default connect(mapStateToProps, authActions)(ResponsiveDrawer);