import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';

import { Col, Row } from "react-bootstrap";
import { Button, Typography, Divider, MenuItem, Select, InputLabel, FormControl, TextField, Autocomplete, Box } from '@mui/material';
import Skeleton from '../../reUsable/skeleton';
import "../accountMain/components/sale.css"
import *  as feedActions from 'store/actions/feedAction'
import moment from 'moment';

import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import DataTable from "./BrandTable";
import { useCallback } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { parseISO } from "date-fns";
import DateBtn from "reUsable/DateBtn";
import SearchBrand from "reUsable/SearchBrand";


function BrandStats({ feedStatsBrands, getFeedStatsBrand }) {
    const [loading, setLoading] = useState(true)
    const [date, setDate] = useState(false)
    const [fields, setFields] = useState({ pixel_id: '', sort_by: 'login_data', phone: '', brand_id: '' })
    const [name, setName] = useState('')
    const [value, setValue] = useState([
        // moment().startOf("year").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
    ]);
    const [type, setType] = useState('pixel_id')
    const [nameLoad, setNameLoad] = useState(true)
    useEffect(() => {
        getData({ ...fields, })
    }, [])

    

    // useEffect(() => {
    // }, [type])

    function handleSearch(type) {
        if (type === "refresh") {
            setValue([moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD"),])
            setFields({ pixel_id: '', sort_by: 'login_data', phone: '', brand_id: '' })
            getData({ pixel_id: '', sort_by: 'login_data', phone: '', brand_id: '' }, "refresh")
            setName('')
            setType('pixel_id')
        } else {
            getData(fields)
        }
    }

    const getData = (obj, refresh) => {
        if (refresh) {
            setLoading(true)
            getFeedStatsBrand({ ...obj, to: moment().format("YYYY-MM-DD"), from: moment().format("YYYY-MM-DD") }, 1).then(() => {
                setDate(false)
                setLoading(false)
            }).catch(err => {
                setDate(false)
                setLoading(false)
            })
        } else {
            setLoading(true)
            getFeedStatsBrand({ ...obj, to: value[1], from: value[0] }, 1).then(() => {
                setDate(false)
                setLoading(false)
            }).catch(err => {
                setDate(false)
                setLoading(false)
            })
        }
    }



    useEffect(() => {
        if (date) {
            getData({ ...fields })
        }
    }, [date])

    return (
        <React.Fragment>
            <div className='container-fluid mt-3 mb-3 pl-0'>
                <Row className='pl-15'>
                    <Col xl className="mb-3 d-flex align-items-center">
                        <Typography className='title-bar-hd-main' color="primary" variant="h5" gutterBottom component="div">
                            Brand Stats
                        </Typography>

                    </Col>
                </Row>
                <DateBtn value={value} setValue={setValue} setDate={setDate} hours={true} />
                <Row className="account-main-row pl-15">

                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">

                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <div className="dateBox">
                                <DatePicker
                                    minDate={new Date(2023, 0, 1)}
                                    slotProps={{
                                        textField: {
                                            helperText: "YYYY / MM / DD",
                                        },
                                    }}
                                    allowSameDateSelection
                                    sx={{ color: "black" }}
                                    label="Start Date"
                                    value={parseISO(value[0])}
                                    onChange={(newValue) => {
                                        setValue([moment(newValue).format("YYYY-MM-DD"), value[1]]);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            className="field-style"
                                            size="small"
                                            style={{ backgroundColor: "white", width: "50%" }}
                                            {...params}
                                        />
                                    )}
                                />
                                <Box sx={{ mx: 2 }}> to </Box>
                                <DatePicker
                                    minDate={new Date(2023, 0, 1)}
                                    slotProps={{
                                        textField: {
                                            helperText: "YYYY / MM / DD",
                                        },
                                    }}
                                    allowSameDateSelection
                                    sx={{ color: "black" }}
                                    label="End Date"
                                    value={parseISO(value[1])}
                                    onChange={(newValue) => {
                                        setValue([value[0], moment(newValue).format("YYYY-MM-DD")]);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            className="field-style"
                                            size="small"
                                            style={{ backgroundColor: "white", width: "50%" }}
                                            {...params}
                                        />
                                    )}
                                />
                            </div>
                        </LocalizationProvider>
                    </Col>
                    <Col xl={3} lg={12} className='mb-3 pid-field-main'>
                        <SearchBrand setter={(value) => setFields({ ...fields, brand_id: value })} brand={fields.brand_id} />
                    </Col>
                    <Col xl={3} lg={12} className='mb-3'>
                        <FormControl className="field-style" size="small">
                            <InputLabel
                                id="demo-simple-select-label"
                            >Sort By</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={fields.sort_by}
                                label="Sort By"
                                onChange={(e) => {
                                    setFields({ ...fields, sort_by: e.target.value })
                                }}
                            >
                                <MenuItem value={"login_data"}>Login</MenuItem>
                                <MenuItem value={"total_uploads"}>Videos</MenuItem>
                                <MenuItem value={"total_published"}>Published Video</MenuItem>
                                <MenuItem value={"update_profile"}>Profile Updates</MenuItem>
                                {/* <MenuItem value={"total_number_of_categories"}>Categories</MenuItem> */}
                                <MenuItem value={"total_sync_product"}>Products</MenuItem>
                                <MenuItem value={"discount"}>Discount</MenuItem>
                                <MenuItem value={"total_sales"}>Sales</MenuItem>
                                {/* <MenuItem value={"total_comments"}>Comments</MenuItem> */}
                                {/* <MenuItem value={"pixel_id"}>PID</MenuItem> */}
                            </Select>
                        </FormControl>

                    </Col>
                    <Col xl={3} lg={12} className='mb-3'>
                        <Button
                            variant="contained"
                            size="medium"
                            className="btn-gen-margin btn-width laptop ml-0"
                            color="primary"
                            startIcon={<SearchIcon />}
                            disableElevation
                            onClick={() => { handleSearch() }}
                        >
                            Search
                        </Button>
                        <Button
                            variant="contained"
                            size="medium"
                            startIcon={<RefreshIcon />}
                            className="btn-gen-margin btn-width laptop"
                            color="success"
                            disableElevation
                            onClick={() => { handleSearch('refresh') }}
                        >
                            Refresh
                        </Button>
                    </Col>
                </Row>
                <Divider className="separater-sale ml-15" />
                <div style={{ height: 580 }} className="pl-3">
                    {
                        loading ? <Skeleton /> :
                            <>
                                <DataTable
                                    nowGet={(page) => getFeedStatsBrand({ ...fields, to: value[1], from: value[0] }, page)}
                                    data={feedStatsBrands}
                                />
                            </>
                    }
                </div>
            </div>

        </React.Fragment>
    )
}
function mapStateToProps({ feedStatsBrands, feedUsers }) {
    return { feedStatsBrands, feedUsers }
}
export default connect(mapStateToProps, feedActions)(BrandStats)